<template>
  <div class="admin-menu-statistics">
    <div class="admin-menu-statistics-content">
      <div class="admin-menu-items">
        <div class="admin-menu-items-content">
          <div
            :class="`admin-menu-item ${mkLink('admin=statistics-home', 'on')}`"
            @click="goFilter({ admin: 'statistics-home', view: _view }, 'admin')"
          >
            <span>{{ $locale["words"]["general"] }}</span>
          </div>
          <div
            :class="`admin-menu-item ${mkLink('admin=statistics-sales', 'on')}`"
            @click="goFilter({ admin: 'statistics-sales', view: _view }, 'admin')"
          >
            <span>{{ $locale["words"]["sales"] }}</span>
          </div>
          <div
            :class="`admin-menu-item ${mkLink('admin=statistics-bags', 'on')}`"
            @click="goFilter({ admin: 'statistics-bags', view: _view }, 'admin')"
          >
            <span>{{ $locale["words"]["sales-bags"] }}</span>
          </div>
          <div
            :class="`admin-menu-item ${mkLink('admin=statistics-users', 'on')}`"
            @click="goFilter({ admin: 'statistics-users', view: _view }, 'admin')"
          >
            <span>{{ $locale["words"]["users"] }}</span>
          </div>
          <div
            :class="`admin-menu-item ${mkLink('admin=statistics-creators', 'on')}`"
            @click="goFilter({ admin: 'statistics-creators', view: _view }, 'admin')"
          >
            <span>{{ $locale["words"]["creators"] }}</span>
          </div>
          <div
            :class="`admin-menu-item ${mkLink('admin=statistics-fans', 'on')}`"
            @click="goFilter({ admin: 'statistics-fans', view: _view }, 'admin')"
          >
            <span>{{ $locale["words"]["fans"] }}</span>
          </div>
          <div
            :class="`admin-menu-item ${mkLink('admin=statistics-connections', 'on')}`"
            @click="goFilter({ admin: 'statistics-connections', view: _view }, 'admin')"
          >
            <span>{{ $locale["words"]["connections"] }}</span>
          </div>
          <div :class="`admin-menu-item ${mkLink('feed=reports', 'on')}`" @click="goFilter({ feed: 'reports', view: _view }, '/')">
            <span>{{ $locale["reports"] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["_view"],
  methods: {
    goFilter: function(filter, path) {
      const currentPath = path ? path : this.$creatorFeed ? this.$creatorFeed.user : "/";
      this.$router.push({ path: currentPath, query: filter }).catch(() => {});
      this.$global.scrollToEl({ el: ".main-feed", topgap: 60 });
      this.$emit("filter");
    },
  },
};
</script>
